export const voices = {
  "af-ZA-AdriNeural": "Afrikaans / South Africa",
  "af-ZA-WillemNeural": "Afrikaans / South Africa",
  "am-ET-MekdesNeural": "Amharic / Ethiopia",
  "am-ET-AmehaNeural": "Amharic / Ethiopia",
  "ar-AE-FatimaNeural": "Arabic / United Arab Emirates",
  "ar-AE-HamdanNeural": "Arabic / United Arab Emirates",
  "ar-BH-LailaNeural": "Arabic / Bahrain",
  "ar-BH-AliNeural": "Arabic / Bahrain",
  "ar-DZ-AminaNeural": "Arabic / Algeria",
  "ar-DZ-IsmaelNeural": "Arabic / Algeria",
  "ar-EG-SalmaNeural": "Arabic / Egypt",
  "ar-EG-ShakirNeural": "Arabic / Egypt",
  "ar-IQ-RanaNeural": "Arabic / Iraq",
  "ar-IQ-BasselNeural": "Arabic / Iraq",
  "ar-JO-SanaNeural": "Arabic / Jordan",
  "ar-JO-TaimNeural": "Arabic / Jordan",
  "ar-KW-NouraNeural": "Arabic / Kuwait",
  "ar-KW-FahedNeural": "Arabic / Kuwait",
  "ar-LB-LaylaNeural": "Arabic / Lebanon",
  "ar-LB-RamiNeural": "Arabic / Lebanon",
  "ar-LY-ImanNeural": "Arabic / Libya",
  "ar-LY-OmarNeural": "Arabic / Libya",
  "ar-MA-MounaNeural": "Arabic / Morocco",
  "ar-MA-JamalNeural": "Arabic / Morocco",
  "ar-OM-AyshaNeural": "Arabic / Oman",
  "ar-OM-AbdullahNeural": "Arabic / Oman",
  "ar-QA-AmalNeural": "Arabic / Qatar",
  "ar-QA-MoazNeural": "Arabic / Qatar",
  "ar-SA-ZariyahNeural": "Arabic / Saudi Arabia",
  "ar-SA-HamedNeural": "Arabic / Saudi Arabia",
  "ar-SY-AmanyNeural": "Arabic / Syria",
  "ar-SY-LaithNeural": "Arabic / Syria",
  "ar-TN-ReemNeural": "Arabic / Tunisia",
  "ar-TN-HediNeural": "Arabic / Tunisia",
  "ar-YE-MaryamNeural": "Arabic / Yemen",
  "ar-YE-SalehNeural": "Arabic / Yemen",
  "az-AZ-BanuNeural": "Azerbaijani / Azerbaijan",
  "az-AZ-BabekNeural": "Azerbaijani / Azerbaijan",
  "bg-BG-KalinaNeural": "Bulgarian / Bulgaria",
  "bg-BG-BorislavNeural": "Bulgarian / Bulgaria",
  "bn-BD-NabanitaNeural": "Bangla / Bangladesh",
  "bn-BD-PradeepNeural": "Bangla / Bangladesh",
  "bn-IN-TanishaaNeural": "Bengali / India",
  "bn-IN-BashkarNeural": "Bengali / India",
  "bs-BA-VesnaNeural": "Bosnian / Bosnia and Herzegovina",
  "bs-BA-GoranNeural": "Bosnian / Bosnia and Herzegovina",
  "ca-ES-JoanaNeural": "Catalan",
  "ca-ES-EnricNeural": "Catalan",
  "ca-ES-AlbaNeural": "Catalan",
  "cs-CZ-VlastaNeural": "Czech / Czechia",
  "cs-CZ-AntoninNeural": "Czech / Czechia",
  "cy-GB-NiaNeural": "Welsh / United Kingdom",
  "cy-GB-AledNeural": "Welsh / United Kingdom",
  "da-DK-ChristelNeural": "Danish / Denmark",
  "da-DK-JeppeNeural": "Danish / Denmark",
  "de-AT-IngridNeural": "German / Austria",
  "de-AT-JonasNeural": "German / Austria",
  "de-CH-LeniNeural": "German / Switzerland",
  "de-CH-JanNeural": "German / Switzerland",
  "de-DE-KatjaNeural": "German / Germany",
  "de-DE-ConradNeural": "German / Germany",
  "de-DE-AmalaNeural": "German / Germany",
  "de-DE-BerndNeural": "German / Germany",
  "de-DE-ChristophNeural": "German / Germany",
  "de-DE-ElkeNeural": "German / Germany",
  "de-DE-GiselaNeural": "German / Germany",
  "de-DE-KasperNeural": "German / Germany",
  "de-DE-KillianNeural": "German / Germany",
  "de-DE-KlarissaNeural": "German / Germany",
  "de-DE-KlausNeural": "German / Germany",
  "de-DE-LouisaNeural": "German / Germany",
  "de-DE-MajaNeural": "German / Germany",
  "de-DE-RalfNeural": "German / Germany",
  "de-DE-TanjaNeural": "German / Germany",
  "de-DE-FlorianMultilingualNeural": "German / Germany",
  "de-DE-SeraphinaMultilingualNeural": "German / Germany",
  "el-GR-AthinaNeural": "Greek / Greece",
  "el-GR-NestorasNeural": "Greek / Greece",
  "en-AU-NatashaNeural": "English / Australia",
  "en-AU-WilliamNeural": "English / Australia",
  "en-AU-AnnetteNeural": "English / Australia",
  "en-AU-CarlyNeural": "English / Australia",
  "en-AU-DarrenNeural": "English / Australia",
  "en-AU-DuncanNeural": "English / Australia",
  "en-AU-ElsieNeural": "English / Australia",
  "en-AU-FreyaNeural": "English / Australia",
  "en-AU-JoanneNeural": "English / Australia",
  "en-AU-KenNeural": "English / Australia",
  "en-AU-KimNeural": "English / Australia",
  "en-AU-NeilNeural": "English / Australia",
  "en-AU-TimNeural": "English / Australia",
  "en-AU-TinaNeural": "English / Australia",
  "en-CA-ClaraNeural": "English / Canada",
  "en-CA-LiamNeural": "English / Canada",
  "en-GB-SoniaNeural": "English / United Kingdom",
  "en-GB-RyanNeural": "English / United Kingdom",
  "en-GB-LibbyNeural": "English / United Kingdom",
  "en-GB-AbbiNeural": "English / United Kingdom",
  "en-GB-AlfieNeural": "English / United Kingdom",
  "en-GB-BellaNeural": "English / United Kingdom",
  "en-GB-ElliotNeural": "English / United Kingdom",
  "en-GB-EthanNeural": "English / United Kingdom",
  "en-GB-HollieNeural": "English / United Kingdom",
  "en-GB-MaisieNeural": "English / United Kingdom",
  "en-GB-NoahNeural": "English / United Kingdom",
  "en-GB-OliverNeural": "English / United Kingdom",
  "en-GB-OliviaNeural": "English / United Kingdom",
  "en-GB-ThomasNeural": "English / United Kingdom",
  "en-HK-YanNeural": "English / Hong Kong SAR",
  "en-HK-SamNeural": "English / Hong Kong SAR",
  "en-IE-EmilyNeural": "English / Ireland",
  "en-IE-ConnorNeural": "English / Ireland",
  "en-IN-NeerjaNeural": "English / India",
  "en-IN-PrabhatNeural": "English / India",
  "en-KE-AsiliaNeural": "English / Kenya",
  "en-KE-ChilembaNeural": "English / Kenya",
  "en-NG-EzinneNeura": "English / Nigeria",
  "en-NG-AbeoNeural": "English / Nigeria",
  "en-NZ-MollyNeural": "English / New Zealand",
  "en-NZ-MitchellNeural": "English / New Zealand",
  "en-PH-RosaNeural": "English / Philippines",
  "en-PH-JamesNeural": "English / Philippines",
  "en-SG-LunaNeural": "English / Singapore",
  "en-SG-WayneNeural": "English / Singapore",
  "en-TZ-ImaniNeural": "English / Tanzania",
  "en-TZ-ElimuNeural": "English / Tanzania",
  "en-US-AvaNeural": "English / United States",
  "en-US-AndrewNeural": "English / United States",
  "en-US-EmmaNeural": "English / United States",
  "en-US-BrianNeural": "English / United States",
  "en-US-JennyNeural": "English / United States",
  "en-US-GuyNeural": "English / United States",
  "en-US-AriaNeural": "English / United States",
  "en-US-DavisNeural": "English / United States",
  "en-US-JaneNeural": "English / United States",
  "en-US-JasonNeural": "English / United States",
  "en-US-SaraNeural": "English / United States",
  "en-US-TonyNeural": "English / United States",
  "en-US-NancyNeural": "English / United States",
  "en-US-AmberNeural": "English / United States",
  "en-US-AnaNeural": "English / United States (Child)",
  "en-US-AshleyNeural": "English / United States",
  "en-US-BrandonNeural": "English / United States",
  "en-US-ChristopherNeural": "English / United States",
  "en-US-CoraNeural": "English / United States",
  "en-US-ElizabethNeural": "English / United States",
  "en-US-EricNeural": "English / United States",
  "en-US-JacobNeural": "English / United States",
  "en-US-JennyMultilingualNeural": "English / United States",
  "en-US-MichelleNeural": "English / United States",
  "en-US-MonicaNeural": "English / United States",
  "en-US-RogerNeural": "English / United States",
  "en-US-RyanMultilingualNeural": "English / United States",
  "en-US-SteffanNeural": "English / United States",
  "en-US-AIGenerate1Neural": "English / United States",
  "en-US-AIGenerate2Neural": "English / United States",
  "en-US-AndrewMultilingualNeural": "English / United States",
  "en-US-AvaMultilingualNeural": "English / United States",
  "en-US-BlueNeural": "English / United States",
  "en-US-BrianMultilingualNeural": "English / United States",
  "en-US-EmmaMultilingualNeural": "English / United States",
  "en-US-AlloyMultilingualNeural": "English / United States",
  "en-US-EchoMultilingualNeural": "English / United States",
  "en-US-FableMultilingualNeural": "English / United States",
  "en-US-OnyxMultilingualNeural": "English / United States",
  "en-US-NovaMultilingualNeural": "English / United States",
  "en-US-ShimmerMultilingualNeural": "English / United States",
  "en-US-AlloyMultilingualNeuralHD": "English / United States",
  "en-US-EchoMultilingualNeuralHD": "English / United States",
  "en-US-FableMultilingualNeuralHD": "English / United States",
  "en-US-OnyxMultilingualNeuralHD": "English / United States",
  "en-US-NovaMultilingualNeuralHD": "English / United States",
  "en-US-ShimmerMultilingualNeuralHD": "English / United States",
  "en-ZA-LeahNeural": "English / South Africa",
  "en-ZA-LukeNeural": "English / South Africa",
  "es-AR-ElenaNeural": "Spanish / Argentina",
  "es-AR-TomasNeural": "Spanish / Argentina",
  "es-BO-SofiaNeural": "Spanish / Bolivia",
  "es-BO-MarceloNeural": "Spanish / Bolivia",
  "es-CL-CatalinaNeural": "Spanish / Chile",
  "es-CL-LorenzoNeural": "Spanish / Chile",
  "es-CO-SalomeNeural": "Spanish / Colombia",
  "es-CO-GonzaloNeural": "Spanish / Colombia",
  "es-CR-MariaNeural": "Spanish / Costa Rica",
  "es-CR-JuanNeural": "Spanish / Costa Rica",
  "es-CU-BelkysNeural": "Spanish / Cuba",
  "es-CU-ManuelNeural": "Spanish / Cuba",
  "es-DO-RamonaNeural": "Spanish / Dominican Republic",
  "es-DO-EmilioNeural": "Spanish / Dominican Republic",
  "es-EC-AndreaNeural": "Spanish / Ecuador",
  "es-EC-LuisNeural": "Spanish / Ecuador",
  "es-ES-ElviraNeural": "Spanish / Spain",
  "es-ES-AlvaroNeural": "Spanish / Spain",
  "es-ES-AbrilNeural": "Spanish / Spain",
  "es-ES-ArnauNeural": "Spanish / Spain",
  "es-ES-DarioNeural": "Spanish / Spain",
  "es-ES-EliasNeural": "Spanish / Spain",
  "es-ES-EstrellaNeural": "Spanish / Spain",
  "es-ES-IreneNeural": "Spanish / Spain",
  "es-ES-LaiaNeural": "Spanish / Spain",
  "es-ES-LiaNeural": "Spanish / Spain",
  "es-ES-NilNeural": "Spanish / Spain",
  "es-ES-SaulNeural": "Spanish / Spain",
  "es-ES-TeoNeural": "Spanish / Spain",
  "es-ES-TrianaNeural": "Spanish / Spain",
  "es-ES-VeraNeural": "Spanish / Spain",
  "es-ES-XimenaNeural": "Spanish / Spain",
  "es-GQ-TeresaNeural": "Spanish / Equatorial Guinea",
  "es-GQ-JavierNeural": "Spanish / Equatorial Guinea",
  "es-GT-MartaNeural": "Spanish / Guatemala",
  "es-GT-AndresNeural": "Spanish / Guatemala",
  "es-HN-KarlaNeural": "Spanish / Honduras",
  "es-HN-CarlosNeural": "Spanish / Honduras",
  "es-MX-DaliaNeural": "Spanish / Mexico",
  "es-MX-JorgeNeural": "Spanish / Mexico",
  "es-MX-BeatrizNeural": "Spanish / Mexico",
  "es-MX-CandelaNeural": "Spanish / Mexico",
  "es-MX-CarlotaNeural": "Spanish / Mexico",
  "es-MX-CecilioNeural": "Spanish / Mexico",
  "es-MX-GerardoNeural": "Spanish / Mexico",
  "es-MX-LarissaNeural": "Spanish / Mexico",
  "es-MX-LibertoNeural": "Spanish / Mexico",
  "es-MX-LucianoNeural": "Spanish / Mexico",
  "es-MX-MarinaNeural": "Spanish / Mexico",
  "es-MX-NuriaNeural": "Spanish / Mexico",
  "es-MX-PelayoNeural": "Spanish / Mexico",
  "es-MX-RenataNeural": "Spanish / Mexico",
  "es-MX-YagoNeural": "Spanish / Mexico",
  "es-NI-YolandaNeural": "Spanish / Nicaragua",
  "es-NI-FedericoNeural": "Spanish / Nicaragua",
  "es-PA-MargaritaNeural": "Spanish / Panama",
  "es-PA-RobertoNeural": "Spanish / Panama",
  "es-PE-CamilaNeural": "Spanish / Peru",
  "es-PE-AlexNeural": "Spanish / Peru",
  "es-PR-KarinaNeural": "Spanish / Puerto Rico",
  "es-PR-VictorNeural": "Spanish / Puerto Rico",
  "es-PY-TaniaNeural": "Spanish / Paraguay",
  "es-PY-MarioNeural": "Spanish / Paraguay",
  "es-SV-LorenaNeural": "Spanish / El Salvador",
  "es-SV-RodrigoNeural": "Spanish / El Salvador",
  "es-US-PalomaNeural": "Spanish / United States",
  "es-US-AlonsoNeural": "Spanish / United States",
  "es-UY-ValentinaNeural": "Spanish / Uruguay",
  "es-UY-MateoNeural": "Spanish / Uruguay",
  "es-VE-PaolaNeural": "Spanish / Venezuela",
  "es-VE-SebastianNeural": "Spanish / Venezuela",
  "et-EE-AnuNeural": "Estonian / Estonia",
  "et-EE-KertNeural": "Estonian / Estonia",
  "eu-ES-AinhoaNeural": "Basque",
  "eu-ES-AnderNeural": "Basque",
  "fa-IR-DilaraNeural": "Persian / Iran",
  "fa-IR-FaridNeural": "Persian / Iran",
  "fi-FI-SelmaNeural": "Finnish / Finland",
  "fi-FI-HarriNeural": "Finnish / Finland",
  "fi-FI-NooraNeural": "Finnish / Finland",
  "fil-PH-BlessicaNeural": "Filipino / Philippines",
  "fil-PH-AngeloNeural": "Filipino / Philippines",
  "fr-BE-CharlineNeural": "French / Belgium",
  "fr-BE-GerardNeural": "French / Belgium",
  "fr-CA-SylvieNeural": "French / Canada",
  "fr-CA-JeanNeural": "French / Canada",
  "fr-CA-AntoineNeural": "French / Canada",
  "fr-CA-ThierryNeural": "French / Canada",
  "fr-CH-ArianeNeural": "French / Switzerland",
  "fr-CH-FabriceNeural": "French / Switzerland",
  "fr-FR-DeniseNeural": "French / France",
  "fr-FR-HenriNeural": "French / France",
  "fr-FR-AlainNeural": "French / France",
  "fr-FR-BrigitteNeural": "French / France",
  "fr-FR-CelesteNeural": "French / France",
  "fr-FR-ClaudeNeural": "French / France",
  "fr-FR-CoralieNeural": "French / France",
  "fr-FR-EloiseNeural": "French / France",
  "fr-FR-JacquelineNeural": "French / France",
  "fr-FR-JeromeNeural": "French / France",
  "fr-FR-JosephineNeural": "French / France",
  "fr-FR-MauriceNeural": "French / France",
  "fr-FR-YvesNeural": "French / France",
  "fr-FR-YvetteNeural": "French / France",
  "fr-FR-RemyMultilingualNeural": "French / France",
  "fr-FR-VivienneMultilingualNeural": "French / France",
  "ga-IE-OrlaNeural": "Irish / Ireland",
  "ga-IE-ColmNeural": "Irish / Ireland",
  "gl-ES-SabelaNeural": "Galician",
  "gl-ES-RoiNeural": "Galician",
  "gu-IN-DhwaniNeural": "Gujarati / India",
  "gu-IN-NiranjanNeural": "Gujarati / India",
  "he-IL-HilaNeural": "Hebrew / Israel",
  "he-IL-AvriNeural": "Hebrew / Israel",
  "hi-IN-SwaraNeural": "Hindi / India",
  "hi-IN-MadhurNeural": "Hindi / India",
  "hr-HR-GabrijelaNeural": "Croatian / Croatia",
  "hr-HR-SreckoNeural": "Croatian / Croatia",
  "hu-HU-NoemiNeural": "Hungarian / Hungary",
  "hu-HU-TamasNeural": "Hungarian / Hungary",
  "hy-AM-AnahitNeural": "Armenian / Armenia",
  "hy-AM-HaykNeural": "Armenian / Armenia",
  "id-ID-GadisNeural": "Indonesian / Indonesia",
  "id-ID-ArdiNeural": "Indonesian / Indonesia",
  "is-IS-GudrunNeural": "Icelandic / Iceland",
  "is-IS-GunnarNeural": "Icelandic / Iceland",
  "it-IT-ElsaNeural": "Italian / Italy",
  "it-IT-IsabellaNeural": "Italian / Italy",
  "it-IT-DiegoNeural": "Italian / Italy",
  "it-IT-BenignoNeural": "Italian / Italy",
  "it-IT-CalimeroNeural": "Italian / Italy",
  "it-IT-CataldoNeural": "Italian / Italy",
  "it-IT-FabiolaNeural": "Italian / Italy",
  "it-IT-FiammaNeural": "Italian / Italy",
  "it-IT-GianniNeural": "Italian / Italy",
  "it-IT-ImeldaNeural": "Italian / Italy",
  "it-IT-IrmaNeural": "Italian / Italy",
  "it-IT-LisandroNeural": "Italian / Italy",
  "it-IT-PalmiraNeural": "Italian / Italy",
  "it-IT-PierinaNeural": "Italian / Italy",
  "it-IT-RinaldoNeural": "Italian / Italy",
  "it-IT-GiuseppeNeural": "Italian / Italy",
  "ja-JP-NanamiNeural": "Japanese / Japan",
  "ja-JP-KeitaNeural": "Japanese / Japan",
  "ja-JP-AoiNeural": "Japanese / Japan",
  "ja-JP-DaichiNeural": "Japanese / Japan",
  "ja-JP-MayuNeural": "Japanese / Japan",
  "ja-JP-NaokiNeural": "Japanese / Japan",
  "ja-JP-ShioriNeural": "Japanese / Japan",
  "ja-JP-MasaruMultilingualNeural": "Japanese / Japan",
  "jv-ID-SitiNeural": "Javanese / Indonesia",
  "jv-ID-DimasNeural": "Javanese / Indonesia",
  "ka-GE-EkaNeural": "Georgian / Georgia",
  "ka-GE-GiorgiNeural": "Georgian / Georgia",
  "kk-KZ-AigulNeural": "Kazakh / Kazakhstan",
  "kk-KZ-DauletNeural": "Kazakh / Kazakhstan",
  "km-KH-SreymomNeural": "Khmer / Cambodia",
  "km-KH-PisethNeural": "Khmer / Cambodia",
  "kn-IN-SapnaNeural": "Kannada / India",
  "kn-IN-GaganNeural": "Kannada / India",
  "ko-KR-SunHiNeural": "Korean / Korea",
  "ko-KR-InJoonNeural": "Korean / Korea",
  "ko-KR-BongJinNeural": "Korean / Korea",
  "ko-KR-GookMinNeural": "Korean / Korea",
  "ko-KR-JiMinNeural": "Korean / Korea",
  "ko-KR-SeoHyeonNeural": "Korean / Korea",
  "ko-KR-SoonBokNeural": "Korean / Korea",
  "ko-KR-YuJinNeural": "Korean / Korea",
  "ko-KR-HyunsuNeural": "Korean / Korea",
  "lo-LA-KeomanyNeural": "Lao / Laos",
  "lo-LA-ChanthavongNeural": "Lao / Laos",
  "lt-LT-OnaNeural": "Lithuanian / Lithuania",
  "lt-LT-LeonasNeural": "Lithuanian / Lithuania",
  "lv-LV-EveritaNeural": "Latvian / Latvia",
  "lv-LV-NilsNeural": "Latvian / Latvia",
  "mk-MK-MarijaNeural": "Macedonian / North Macedonia",
  "mk-MK-AleksandarNeural": "Macedonian / North Macedonia",
  "ml-IN-SobhanaNeural": "Malayalam / India",
  "ml-IN-MidhunNeural": "Malayalam / India",
  "mn-MN-YesuiNeural": "Mongolian / Mongolia",
  "mn-MN-BataaNeural": "Mongolian / Mongolia",
  "mr-IN-AarohiNeural": "Marathi / India",
  "mr-IN-ManoharNeural": "Marathi / India",
  "ms-MY-YasminNeural": "Malay / Malaysia",
  "ms-MY-OsmanNeural": "Malay / Malaysia",
  "mt-MT-GraceNeural": "Maltese / Malta",
  "mt-MT-JosephNeural": "Maltese / Malta",
  "my-MM-NilarNeural": "Burmese / Myanmar",
  "my-MM-ThihaNeural": "Burmese / Myanmar",
  "nb-NO-PernilleNeural": "Norwegian Bokmål / Norway",
  "nb-NO-FinnNeural": "Norwegian Bokmål / Norway",
  "nb-NO-IselinNeural": "Norwegian Bokmål / Norway",
  "ne-NP-HemkalaNeural": "Nepali / Nepal",
  "ne-NP-SagarNeural": "Nepali / Nepal",
  "nl-BE-DenaNeural": "Dutch / Belgium",
  "nl-BE-ArnaudNeural": "Dutch / Belgium",
  "nl-NL-FennaNeural": "Dutch / Netherlands",
  "nl-NL-MaartenNeural": "Dutch / Netherlands",
  "nl-NL-ColetteNeural": "Dutch / Netherlands",
  "pl-PL-AgnieszkaNeural": "Polish / Poland",
  "pl-PL-MarekNeural": "Polish / Poland",
  "pl-PL-ZofiaNeural": "Polish / Poland",
  "ps-AF-LatifaNeural": "Pashto / Afghanistan",
  "ps-AF-GulNawazNeural": "Pashto / Afghanistan",
  "pt-BR-FranciscaNeural": "Portuguese / Brazil",
  "pt-BR-AntonioNeural": "Portuguese / Brazil",
  "pt-BR-BrendaNeural": "Portuguese / Brazil",
  "pt-BR-DonatoNeural": "Portuguese / Brazil",
  "pt-BR-ElzaNeural": "Portuguese / Brazil",
  "pt-BR-FabioNeural": "Portuguese / Brazil",
  "pt-BR-GiovannaNeural": "Portuguese / Brazil",
  "pt-BR-HumbertoNeural": "Portuguese / Brazil",
  "pt-BR-JulioNeural": "Portuguese / Brazil",
  "pt-BR-LeilaNeural": "Portuguese / Brazil",
  "pt-BR-LeticiaNeural": "Portuguese / Brazil",
  "pt-BR-ManuelaNeural": "Portuguese / Brazil",
  "pt-BR-NicolauNeural": "Portuguese / Brazil",
  "pt-BR-ValerioNeural": "Portuguese / Brazil",
  "pt-BR-YaraNeural": "Portuguese / Brazil",
  "pt-BR-ThalitaNeural": "Portuguese / Brazil",
  "pt-PT-RaquelNeural": "Portuguese / Portugal",
  "pt-PT-DuarteNeural": "Portuguese / Portugal",
  "pt-PT-FernandaNeural": "Portuguese / Portugal",
  "ro-RO-AlinaNeural": "Romanian / Romania",
  "ro-RO-EmilNeural": "Romanian / Romania",
  "ru-RU-SvetlanaNeural": "Russian / Russia",
  "ru-RU-DmitryNeural": "Russian / Russia",
  "ru-RU-DariyaNeural": "Russian / Russia",
  "si-LK-ThiliniNeural": "Sinhala / Sri Lanka",
  "si-LK-SameeraNeural": "Sinhala / Sri Lanka",
  "sk-SK-ViktoriaNeural": "Slovak / Slovakia",
  "sk-SK-LukasNeural": "Slovak / Slovakia",
  "sl-SI-PetraNeural": "Slovenian / Slovenia",
  "sl-SI-RokNeural": "Slovenian / Slovenia",
  "so-SO-UbaxNeural": "Somali / Somalia",
  "so-SO-MuuseNeural": "Somali / Somalia",
  "sq-AL-AnilaNeural": "Albanian / Albania",
  "sq-AL-IlirNeural": "Albanian / Albania",
  "sr-Latn-RS-NicholasNeural": "Serbian (Latin) / Serbia",
  "sr-Latn-RS-SophieNeural": "Serbian (Latin) / Serbia",
  "sr-RS-SophieNeural": "Serbian (Cyrillic) / Serbia",
  "sr-RS-NicholasNeural": "Serbian (Cyrillic) / Serbia",
  "su-ID-TutiNeural": "Sundanese / Indonesia",
  "su-ID-JajangNeural": "Sundanese / Indonesia",
  "sv-SE-SofieNeural": "Swedish / Sweden",
  "sv-SE-MattiasNeural": "Swedish / Sweden",
  "sv-SE-HilleviNeural": "Swedish / Sweden",
  "sw-KE-ZuriNeural": "Swahili / Kenya",
  "sw-KE-RafikiNeural": "Swahili / Kenya",
  "sw-TZ-RehemaNeural": "Swahili / Tanzania",
  "sw-TZ-DaudiNeural": "Swahili / Tanzania",
  "ta-IN-PallaviNeural": "Tamil / India",
  "ta-IN-ValluvarNeural": "Tamil / India",
  "ta-LK-SaranyaNeural": "Tamil / Sri Lanka",
  "ta-LK-KumarNeural": "Tamil / Sri Lanka",
  "ta-MY-KaniNeural": "Tamil / Malaysia",
  "ta-MY-SuryaNeural": "Tamil / Malaysia",
  "ta-SG-VenbaNeural": "Tamil / Singapore",
  "ta-SG-AnbuNeural": "Tamil / Singapore",
  "te-IN-ShrutiNeural": "Telugu / India",
  "te-IN-MohanNeural": "Telugu / India",
  "th-TH-PremwadeeNeural": "Thai / Thailand",
  "th-TH-NiwatNeural": "Thai / Thailand",
  "th-TH-AcharaNeural": "Thai / Thailand",
  "tr-TR-EmelNeural": "Turkish / Türkiye",
  "tr-TR-AhmetNeural": "Turkish / Türkiye",
  "uk-UA-PolinaNeural": "Ukrainian / Ukraine",
  "uk-UA-OstapNeural": "Ukrainian / Ukraine",
  "ur-IN-GulNeural": "Urdu / India",
  "ur-IN-SalmanNeural": "Urdu / India",
  "ur-PK-UzmaNeural": "Urdu / Pakistan",
  "ur-PK-AsadNeural": "Urdu / Pakistan",
  "uz-UZ-MadinaNeural": "Uzbek / Uzbekistan",
  "uz-UZ-SardorNeural": "Uzbek / Uzbekistan",
  "vi-VN-HoaiMyNeural": "Vietnamese / Vietnam",
  "vi-VN-NamMinhNeural": "Vietnamese / Vietnam",
  "wuu-CN-XiaotongNeural": "Chinese / Wu, Simplified",
  "wuu-CN-YunzheNeural": "Chinese / Wu, Simplified",
  "yue-CN-XiaoMinNeural": "Chinese / Cantonese, Simplified",
  "yue-CN-YunSongNeural": "Chinese / Cantonese, Simplified",
  "zh-CN-XiaoxiaoNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-YunxiNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-YunjianNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaoyiNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-YunyangNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaochenNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaohanNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaomengNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaomoNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaoqiuNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaoruiNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaoshuangNeural": "Chinese / Mandarin, Simplified (Child)",
  "zh-CN-XiaoyanNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaoyouNeural": "Chinese / Mandarin, Simplified (Child)",
  "zh-CN-XiaozhenNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-YunfengNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-YunhaoNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-YunxiaNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-YunyeNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-YunzeNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaochenMultilingualNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaorouNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaoxiaoDialectsNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaoxiaoMultilingualNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaoyuMultilingualNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-YunjieNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-YunyiMultilingualNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-guangxi-YunqiNeural": "Chinese / Guangxi Accent Mandarin, Simplified",
  "zh-CN-henan-YundengNeural": "Chinese / Zhongyuan Mandarin Henan, Simplified",
  "zh-CN-liaoning-XiaobeiNeural": "Chinese / Northeastern Mandarin, Simplified",
  "zh-CN-liaoning-YunbiaoNeural": "Chinese / Northeastern Mandarin, Simplified",
  "zh-CN-shaanxi-XiaoniNeural":
    "Chinese / Zhongyuan Mandarin Shaanxi, Simplified",
  "zh-CN-shandong-YunxiangNeural": "Chinese / Jilu Mandarin, Simplified",
  "zh-CN-sichuan-YunxiNeural": "Chinese / Southwestern Mandarin, Simplified",
  "zh-HK-HiuMaanNeural": "Chinese / Cantonese, Traditional",
  "zh-HK-WanLungNeural": "Chinese / Cantonese, Traditional",
  "zh-HK-HiuGaaiNeural": "Chinese / Cantonese, Traditional",
  "zh-TW-HsiaoChenNeural": "Chinese / Taiwanese Mandarin, Traditional",
  "zh-TW-YunJheNeural": "Chinese / Taiwanese Mandarin, Traditional",
  "zh-TW-HsiaoYuNeural": "Chinese / Taiwanese Mandarin, Traditional",
  "zu-ZA-ThandoNeural": "Zulu / South Africa",
  "zu-ZA-ThembaNeural": "Zulu / South Africa",
};
