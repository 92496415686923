<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-form ref="form" v-model="valid">
            <v-card-text>
              <v-row class="mt-4 mb-2">
                <v-col cols="12" md="6">
                  <h2 class="font-weight-bold mb-8">Party 1</h2>
                  <v-autocomplete
                    v-model="callSettings.party1.prefix"
                    :items="preparedPhonePrefix"
                    item-text="label"
                    item-value="value"
                    label="Prefix"
                    class="max-width"
                  />

                  <v-text-field
                    v-model="callSettings.party1.phoneNumber"
                    :rules="[
                      rules.required,
                      rules.onlyNumbers,
                      rules.phoneNumber.length,
                    ]"
                    maxlength="20"
                    label="Phone number"
                    hide-details="auto"
                    class="mb-6 max-width"
                  ></v-text-field>

                  <v-autocomplete
                    v-model="callSettings.party1.voice"
                    :items="preparedVoices"
                    item-text="label"
                    item-value="value"
                    label="Voices"
                    class="max-width"
                  />
                </v-col>

                <v-divider vertical class="d-sm-none d-md-block" />

                <v-col cols="12" md="6">
                  <h2 class="font-weight-bold mb-8">Party 2</h2>
                  <v-autocomplete
                    v-model="callSettings.party2.prefix"
                    :items="preparedPhonePrefix"
                    item-text="label"
                    item-value="value"
                    label="Prefix"
                    class="max-width"
                  />

                  <v-text-field
                    v-model="callSettings.party2.phoneNumber"
                    :rules="[
                      rules.required,
                      rules.onlyNumbers,
                      rules.phoneNumber.length,
                    ]"
                    maxlength="20"
                    label="Phone number"
                    hide-details="auto"
                    class="mb-6 max-width"
                  ></v-text-field>

                  <v-autocomplete
                    v-model="callSettings.party2.voice"
                    :items="preparedVoices"
                    item-text="label"
                    item-value="value"
                    label="Voices"
                    class="max-width"
                  />
                </v-col>
              </v-row>

              <v-row v-if="showSettings">
                <v-col>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Settings
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-form>
                          <v-slider
                            v-model="callSettings.originalVolume"
                            label="Original speech volume:"
                            hint="Range is 0.0 - 1.0, default is 0.1"
                            persistent-hint
                            thumb-label="always"
                            max="1"
                            min="0"
                            step="0.01"
                            class="mb-8"
                          />

                          <v-slider
                            v-model="callSettings.translatedVolume"
                            label="Translated speech volume:"
                            hint="Range is 0.0 - 1.0, default is 0.15"
                            persistent-hint
                            thumb-label="always"
                            max="1"
                            min="0"
                            step="0.01"
                            class="mb-8"
                          />

                          <!--                          <v-slider-->
                          <!--                            v-model="callSettings.splitAfterCharCount"-->
                          <!--                            label="Split after char count:"-->
                          <!--                            hint="Specifies the minimum number of characters after which a long sentence is allowed to split (range is 0 - 50, default 20)"-->
                          <!--                            persistent-hint-->
                          <!--                            thumb-label="always"-->
                          <!--                            max="50"-->
                          <!--                            min="0"-->
                          <!--                            step="1"-->
                          <!--                            class="mb-8"-->
                          <!--                          />-->

                          <!--                          <v-slider-->
                          <!--                            v-model="callSettings.equalIntervalSplitRatio"-->
                          <!--                            label="Equal interval split ratio:"-->
                          <!--                            hint="Sets the ratio of the length of a stable initial part of consequent translations where a split may occur (range is 0.0 - 1.0, default is 0.5)"-->
                          <!--                            persistent-hint-->
                          <!--                            thumb-label="always"-->
                          <!--                            max="1"-->
                          <!--                            min="0"-->
                          <!--                            step="0.01"-->
                          <!--                            class="mb-8"-->
                          <!--                          />-->
                        </v-form>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>

              <v-row justify="space-around" class="mt-10 mb-7">
                <v-btn
                  depressed
                  large
                  color="primary"
                  :loading="loading"
                  :disabled="loading"
                  @click="makeCall"
                >
                  Make Call
                </v-btn>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>

      <v-col cols="12" class="mb-4">
        <v-card ref="resultCard">
          <v-card-text style="position: relative">
            <h2 class="font-weight-bold mb-4">Call Transcript</h2>
            <div v-if="apiError" class="api-error">{{ apiError }}</div>
            <v-btn
              v-show="showIframe"
              @click="copyLink"
              :color="linkCopied ? 'success' : 'primary'"
              class="copy-button"
            >
              <span v-if="!linkCopied">Copy link</span>
              <span v-else>Link copied</span>
            </v-btn>
            <iframe
              v-show="showIframe"
              :src="iframeSrc"
              ref="iframeTranscript"
              class="iframe-transcript"
            ></iframe>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { voices } from "@/constants/voices";
import { phonePrefixes } from "@/constants/phonePrefixes";
import ApiService from "@/services/ApiService";

export default {
  name: "MainComponent",

  data: () => ({
    voices: voices,
    phonePrefixes: phonePrefixes,
    loading: false,
    data: null,
    apiError: false,
    linkCopied: false,

    callSettings: {
      party1: {
        prefix: phonePrefixes["Czech Republic"],
        phoneNumber: "",
        voice: "cs-CZ-AntoninNeural",
      },
      party2: {
        prefix: phonePrefixes["Czech Republic"],
        phoneNumber: "",
        voice: "en-GB-AbbiNeural",
      },
      originalVolume: 0.15,
      translatedVolume: 0.05,
      splitAfterCharCount: 22,
      equalIntervalSplitRatio: 0.55,
    },

    valid: true,
    rules: {
      required: (value) => !!value || "Required.",
      onlyNumbers: (value) =>
        /^\d+$/.test(value) || "Allowed only digits without spaces.",
      phoneNumber: {
        length: (value) => value.length <= 20 || "Max 20 characters",
      },
    },
  }),

  computed: {
    showSettings() {
      return window.location.hostname !== "livetranslate.vocalls.ai";
    },

    showIframe() {
      return this.data;
    },

    iframeSrc() {
      if (this.data) {
        return `https://pcontrol.livetranslate.vocalls.ai/ActiveCalls/Detail/${this.data.Guid}`;
        //return `http://10.42.65.36/ActiveCalls/Detail/${this.data.Guid}`;
      }

      return "";
    },

    preparedPhonePrefix() {
      return Object.keys(phonePrefixes).map(function (key) {
        return {
          label: `${phonePrefixes[key]} ${key}`,
          value: phonePrefixes[key],
        };
      });
    },

    preparedVoices() {
      return Object.keys(voices).map(function (key) {
        return {
          label: `${key} (${voices[key]})`,
          value: key,
        };
      });
    },
  },

  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.iframeSrc);
      this.linkCopied = true;
      setTimeout(() => {
        this.linkCopied = false;
      }, 3000);
    },

    async makeCall() {
      this.$refs.form.validate();
      if (this.valid) {
        this.data = null;
        this.apiError = false;
        this.loading = true;
        this.linkCopied = false;

        try {
          const response = await ApiService.makeCall(
            this.callSettings.party1.voice,
            this.callSettings.party1.prefix,
            this.callSettings.party1.phoneNumber,
            this.callSettings.party2.voice,
            this.callSettings.party2.prefix,
            this.callSettings.party2.phoneNumber,
            this.callSettings.originalVolume,
            this.callSettings.translatedVolume
          );

          console.log(response.data);
          if (response.data.Success) {
            this.data = response.data;
          } else {
            this.apiError = "Connection failed. Please repeat the call.";
          }
        } catch (e) {
          console.error(e);
          this.apiError =
            "Something went wrong, please try again or contact the administrator.";
        }

        this.$nextTick(() => {
          this.$refs.resultCard.$el.scrollIntoView({
            block: "end",
            scrollBehavior: "smooth",
          });
        });
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.api-error {
  color: #ff5252;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.copy-button {
  position: absolute;
  right: 20px;
  top: 20px;
}

.iframe-transcript {
  width: 100%;
  border: none;
  height: 500px;
}

@media only screen and (min-width: 960px) {
  .max-width {
    max-width: 500px;
  }
}
</style>
