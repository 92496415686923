import axios from "axios";
export class ApiService {
  constructor() {}

  async makeCall(
    voiceParty1,
    prefixParty1,
    phoneParty1,
    voiceParty2,
    prefixParty2,
    phoneParty2,
    originalVolume,
    translatedVolume
  ) {
    const body = {
      Party1: {
        EndpointType: 0,
        VoiceVendor: "Microsoft",
        Pitch: 1,
        Speed: 1,
        Language: voiceParty1.slice(0, voiceParty1.lastIndexOf("-")),
        VoiceName: voiceParty1,
        Endpoint: prefixParty1 + phoneParty1,
        OriginalSpeechVolume: originalVolume,
        OwnTranslateVolue: translatedVolume,
      },
      Party2: {
        EndpointType: 0,
        VoiceVendor: "Microsoft",
        Pitch: 1,
        Speed: 1,
        Language: voiceParty2.slice(0, voiceParty2.lastIndexOf("-")),
        VoiceName: voiceParty2,
        Endpoint: prefixParty2 + phoneParty2,
        OriginalSpeechVolume: originalVolume,
        OwnTranslateVolue: translatedVolume,
      },
    };

    console.log(body);
    const response = await axios.post("/make-call", body);
    console.log(response);
    return response;
  }
}

export default new ApiService();
